<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading clients ...
        </div>
        <div class="notification is-danger" v-if="loading == false && active_clients.length == 0">
            No clients can be found.
        </div>

        <div v-if="loading == false && active_clients.length > 0">
            <button @click="exportXLS()" class="button is-link is-pulled-right">Export For Excel</button>
            <h4 class="title is-size-4">Active Clients</h4>
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" id="clientstable">
                <thead>
                    <tr>
                        <th>Client Group</th>
                        <th>Company Reg.</th>
                        <th>Client</th>
                        <th>Address 1</th>
                        <th>Address 2</th>
                        <th>Address 3</th>
                        <th>Town</th>
                        <th>Postcode</th>
                        <th style="display:none">Last Booking</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in active_clients" :key="row.id">
                        <td>{{ row.client_group_name  }}</td>
                        <td>{{ row.company_registration  }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.address1 }}</td>
                        <td>{{ row.address2 }}</td>
                        <td>{{ row.address3 }}</td>
                        <td>{{ row.city }}</td>
                        <td>{{ row.postcode }}</td>
                        <td style="display:none">{{ row.last_booking }}</td>
                    </tr>
                
                </tbody>

            </table>
        </div>

        <div v-if="loading == false && archived_clients.length > 0" class="mt-6">
            <h4 class="title is-size-4">Archived Clients</h4>
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" >
                <thead>
                    <tr>
                        <th>Client Group</th>
                        <th>Company Reg.</th>
                        <th>Client</th>
                        <th>Address 1</th>
                        <th>Address 2</th>
                        <th>Address 3</th>
                        <th>Town</th>
                        <th>Postcode</th>
                        <th style="display:none">Last Booking</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in archived_clients" :key="row.id">
                        <td>{{ row.client_group_name  }}</td>
                        <td>{{ row.company_registration  }}</td>
                        <td>{{ row.name }}</td>
                        <td>{{ row.address1 }}</td>
                        <td>{{ row.address2 }}</td>
                        <td>{{ row.address3 }}</td>
                        <td>{{ row.city }}</td>
                        <td>{{ row.postcode }}</td>
                        <td style="display:none">{{ row.last_booking }}</td>
                    </tr>
                
                </tbody>

            </table>
        </div>
        
    </div>
</template>

<script>
import * as XLSX from 'xlsx'

import UtilityService from '@/services/UtilityService.js';
//import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'ClientList',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   clients: [],
                   loading: true,
                   alertText: '',
                   accessToken: '',
                   working: false
                   
            }
        },
        methods: {
            async getAllClients() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "clients?include_last_booking=1&order_by=client_groups.name ASC,clients.name ASC")
                .then(
                    (clients => {
                        this.$set(this, "clients", clients);
                        this.loading=false
                    }).bind(this)
                );
            },
            exportXLS() {
		
                var wb = XLSX.utils.table_to_book(document.getElementById('clientstable'), {sheet:"Sheet JS"});
                XLSX.writeFile(wb, 'Client_List.xlsx')

            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getAllClients();
                
            });
        
        },
        computed : {
            active_clients: function () {
            
                let active_clients =  this.clients.filter(function(u) {
                    if(  u.active == 1 && (u.risk_level == 1 || u.risk_level == 2)) 
                    {
                    return true
                    }
                    else
                    {
                    return false
                    }
                })

                if(this.search != '' && this.search != null)
                {
                    active_clients = active_clients.filter(
                        row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.sage_code != null && row.sage_code.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }

                return active_clients
            
            },
            archived_clients: function () {
            
                let archived_clients =  this.clients.filter(function(u) {
                    if(  u.active == 1 && (u.risk_level == 3)) 
                    {
                    return true
                    }
                    else
                    {
                    return false
                    }
                })

                if(this.search != '' && this.search != null)
                {
                    archived_clients = archived_clients.filter(
                        row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.sage_code != null && row.sage_code.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }

                return archived_clients
            
            },
            
        }
    }
</script>