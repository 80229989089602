<template>
    <div class="  is-fullheight sticky-sidebar" >
      <aside class="menu column  aside   is-fullheight" >
       <p class="menu-label">
Billing
      </p>
       <ul class="menu-list">
         <li>
           <a class="">General</a>
           <ul>
             <li @click="activeMenuLink = '/billing'"><router-link to="/billing"  :class="{'is-active':activeMenuLink === '/billing'}">Dashboard</router-link></li>
             <li @click="activeMenuLink = '/billing/missing-timesheets'"><router-link to="/billing/missing-timesheets"  :class="{'is-active':activeMenuLink === '/billing/missing-timesheets'}">Missing Timesheets</router-link></li>
             <li @click="activeMenuLink = '/billing/saved-timesheets'"><router-link to="/billing/saved-timesheets"  :class="{'is-active':activeMenuLink === '/billing/saved-timesheets'}">Saved Timesheets</router-link></li>
             <li @click="activeMenuLink = '/billing/submitted-timesheets'"><router-link to="/billing/submitted-timesheets"  :class="{'is-active':activeMenuLink === '/billing/submitted-timesheets'}">Submitted Timesheets</router-link></li>
             
             <li @click="activeMenuLink = '/billing/approved-timesheets'"><router-link to="/billing/approved-timesheets"  :class="{'is-active':activeMenuLink === '/billing/approved-timesheets'}">Approved Timesheets</router-link></li>
             <li @click="activeMenuLink = '/billing/processed-timesheets'"><router-link to="/billing/processed-timesheets"  :class="{'is-active':activeMenuLink === '/billing/processed-timesheets'}">Processed Timesheets</router-link></li>
             
             
           </ul>
         </li>
         <li>
           <a class="">Payroll </a>
           <ul>
            <li @click="activeMenuLink = '/billing/payroll-report'"><router-link to="/billing/payroll-report"  :class="{'is-active':activeMenuLink === '/billing/payroll-report'}">Payroll Report</router-link></li>
            <li @click="activeMenuLink = '/billing/member-payroll-summary'"><router-link to="/billing/member-payroll-summary"  :class="{'is-active':activeMenuLink === '/billing/member-payroll-summary'}">Member Payroll Summarys</router-link></li>
            <li @click="activeMenuLink = '/billing/client-invoices'"><router-link to="/billing/client-invoices"  :class="{'is-active':activeMenuLink === '/billing/client-invoices'}">Client Invoices</router-link></li>
            <li @click="activeMenuLink = '/billing/sage-numbers'"><router-link to="/billing/sage-numbers"  :class="{'is-active':activeMenuLink === '/billing/sage-numbers'}">Works Numbers</router-link></li>
           </ul>
         </li>
         

         <li>
           <a class="">Reports</a>
           <ul>
            <li @click="activeMenuLink = '/billing/missing-references'"><router-link to="/billing/missing-references"  :class="{'is-active':activeMenuLink === '/billing/missing-references'}">Missing References</router-link></li>
            <li @click="activeMenuLink = '/billing/unsent-invoices'"><router-link to="/billing/unsent-invoices"  :class="{'is-active':activeMenuLink === '/billing/unsent-invoices'}">Un-Sent Invoices</router-link></li>
            <li @click="activeMenuLink = '/billing/client-list'"><router-link to="/billing/client-list"  :class="{'is-active':activeMenuLink === '/billing/client-list'}">Client List</router-link></li>
            <li @click="activeMenuLink = '/billing/trust-bookings'"><router-link to="/billing/trust-bookings"  :class="{'is-active':activeMenuLink === '/billing/trust-bookings'}">Processed Trust Bookings</router-link></li>
           </ul>
         </li>
         
         
         
       </ul>
       
     </aside>
     
    </div>
 </template>
 
 <script>
 export default {
   name: 'SideBarBilling',
   components: {
   },
   data() {
     return { activeMenuLink: null }
   },
   mounted() {
     //this.activeMenuLink = this.$route.path.substring(1)
     this.activeMenuLink = this.$route.path
     console.log(this.activeMenuLink)
   },
   watch: {
    $route () { 
      
      this.activeMenuLink = this.$route.path
    },
    

  }
 }
 
 </script>