<template>
    <div>
        <div class="notification is-warning" v-if="loading == true">
            Loading client  ...
        </div>
        <div class="notification is-danger" v-if="loading === false && client.id == 0">
            Client details cannot be found
        </div>

        <div v-if="loading == false && client.id > 0">
            <h4 class="title is-size-4">Client Details <span class="tag is-warning" v-if="this.client.hide_client==1"> Hidden</span></h4>
            <form @submit.prevent="updateClient()">
                
                
                <div class="field ">
                    <div class="columns">
                        <div class="column">
                            <label class="label">Name</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="client.name"  ></div>
                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Code</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="client.code"  ></div>
                               
                            </div>
                        </div>
                       
                    </div>

                    <div class="field">
                    <label class="label">Address </label>
                    <div class="control columns" >
                        <div class="column"><input class="input" type="text" v-model="client.address1"  ></div>
                        <div class="column"><input class="input" type="text" v-model="client.address2"  ></div>
                        <div class="column"><input class="input" type="text" v-model="client.address3"  ></div>
                    </div>
                </div>
                <div class="field">
                    <label class="label">City / Postcode </label>
                    <div class="control columns" >
                        <div class="column"><input class="input" type="text" v-model="client.city"  ></div>
                        <div class="column"><input class="input" type="text" v-model="client.postcode"  ></div>
                        
                    </div>
                </div>

                    <div class="columns">
                        <div class="column">
                            <label class="label">Client Group</label>
                            <div class="control columns" >
                                <div class="column">
                                    <select v-model="client.client_group_id" required class="input" @change="client_group_id=client.client_group_id">
                                        <option value="">Please select ...</option>
                                        <option v-for="row in client_groups" :key="row.id" :value="row.id">{{ row.name }}</option>
                                    </select>

                                </div>
                               
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Billing Profile</label>
                            <div class="control columns" >
                                <div class="column">
                                    <select v-model="client.client_group_billing_profile_id" required class="input">
                                        <option value="">Please select ...</option>
                                        <option v-for="row in client_group_billing_profiles" :key="row.id" :value="row.id">{{ row.label }} <span v-if="row.email != '' && row.eamil != null">({{ row.email}})</span></option>
                                    </select>
                                </div>
                               
                            </div>
                        </div>
                       
                    </div>

                    <div class="columns">
                        <div class="column">
                            <label class="label">Cost Centre Code</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="client.cost_centre"  ></div>
                            </div>
                        </div>
                        <div class="column">
                            <label class="label">Default Break</label>
                            <div class="control columns" >
                                <div class="column"><input class="input" type="text" v-model="client.default_break" required ></div>
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div class="field is-grouped" v-if="this.user.billing==1"><div class="control"><button type="submit" class="button is-success" >Update Client Details</button></div></div>
            </form>
            
            <hr />
            <h4 class="title is-size-4">Wards</h4>
            <div class="notification is-danger" v-if="client.wards.length == 0">
                No wards can be found for this client
            </div>
            <table class="table is-fullwidth is-striped is-hoverable" v-if="client.wards.length > 0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Telephone</th>
                        <th>Cost Centre</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in client.wards" :key="row.id">
                        <td>{{ row.name }}</td>
                        <td>{{ row.code }}</td>
                        <td>{{ row.telephone }}</td>
                        <td>{{ row.cost_centre }}</td>
                    </tr>
                
                </tbody>

            </table>

            <button class="button is-small is-success" @click="showNewWardModal()">Add New Ward</button>

            <hr />
            <h4 class="title is-size-4">Confirmed Bookings</h4>
            <div class="notification is-danger" v-if="client.bookings.length == 0">
                No confirmed bookings can be found for this client
            </div>
            <table class="table is-fullwidth is-striped is-hoverable is-size-7" v-if="client.bookings.length > 0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Ref</th>
                        <th>Ward</th>
                        <th>Member</th>
                        <th>Start Date/Time</th>
                        <th>End Date/Time</th>
                        <th>Break</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in client.bookings" :key="'booking' + row.id">
                        <td><a :href="'/bookings/' + row.id" target="_blank">{{ row.id }}</a></td>
                        <td>{{ row.reference }}</td>
                        <td>{{ row.ward_name }}</td>
                        <td>{{ row.forename}} {{row.surname}} <span class="tag is-warning" v-if="row.sleeping_nurse==1">Sleeping Nurse</span></td>
                        <td>{{ row.start_date}} {{ row.start_time}}</td>
                        <td>{{ row.end_date}} {{ row.end_time}}</td>
                        <td>{{ row.break_time}}</td>
                    </tr>
                
                </tbody>

            </table>

            <hr />
            <h4 class="title is-size-4">Client Actions</h4>
            <div class="buttons">
                <div class="button is-danger" v-if="client.bookings.length == 0" @click="removeClient()">REMOVE CLIENT</div>
                <div class="button is-link" v-if="client.hide_client==0"  @click="hideClient()">HIDE CLIENT</div>
            </div>

            <div class="modal" :class="{'is-active': showModal==true}">
                <div class="modal-background"></div>
                <div class="modal-card" >
                    <form @submit.prevent="saveWard()">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Add A New Ward</p>
                        <button class="delete" aria-label="close" @click="closeModal()"></button>
                    </header>
                    <section class="modal-card-body " >
                        
                        <div class="field">
                            <label class="label">Ward Name</label>
                            <div class="control">
                                <input class="input" type="text" v-model="name" required >
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Code</label>
                            <div class="control">
                                <input class="input" type="text" v-model="code"  >
                                
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Description</label>
                            <div class="control">
                                <input class="input" type="text" v-model="description"  >
                                
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Telephone</label>
                            <div class="control">
                                <input class="input" type="text" v-model="telephone"  >
                                
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Cost Centre</label>
                            <div class="control">
                                <input class="input" type="text" v-model="cost_centre"  >
                                
                            </div>
                        </div>
                    </section>
                    
                    <footer class="modal-card-foot" >
                    <button type="submit" class="button is-success" :disabled="working==true">Save</button>
                    <button type="button" class="button" @click="closeModal()" :disabled="working==true">Cancel</button>
                    </footer>
                </form>
                </div>
                
            </div>

           
        </div>

        
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import BookingService from '@/services/BookingService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

export default {
    
      name: 'ClientGroups',
        mixins: [MemberMixin],
        data: function () {
            return {
                   search: '',
                   client: [],
                   loading: true,
                   working: false,
                   accessToken: '',
                   showModal: false,
                   client_groups: [],
                   client_group_billing_profiles: [],
                   user: [{billing: 0}],
                   name : '',
                    code: '',
                    description: '',
                    telephone: '',
                    cost_centre: '',
                    client_group_id: 0
            }
        },
        methods: {
            async getClient() {
                this.loading=true
                UtilityService.getSimpleApiData(this.accessToken, "clients/" + this.$route.params.id + "?with_bookings=1")
                .then(
                    (client => {
                        this.$set(this, "client", client);

                        this.getClientGroupBillingProfiles();

                        this.loading=false
                    }).bind(this)
                );

                this.name = ''
                this.code= ''
                this.description= ''
                this.telephone= ''
                this.cost_centre= ''

            },
            showNewWardModal() {
                this.showModal = true
            },
            async saveWard() {
                this.working = true
                var formdata = { 
                    client_id: this.$route.params.id,
                    name : this.name,
                    code: this.code,
                    description: this.description,
                    telephone: this.telephone,
                    cost_centre: this.cost_centre,
                    
                    
                }; 

                BookingService.saveWard(this.accessToken, formdata)
                .then((response) => {
                    this.closeModal();
                    console.log(response)
                    this.working = false
                    this.getClient();
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            async updateClient() {
                this.working = true;
                
                    var formdata = { 
                        _method: 'patch',
                        name: this.client.name,
                        code: this.client.code,
                        cost_centre: this.client.cost_centre,
                        default_break: this.client.default_break,
                        client_group_id: this.client.client_group_id,
                        client_group_billing_profile_id: this.client.client_group_billing_profile_id,
                        address1: this.client.address1,
                        address2: this.client.address2,
                        address3: this.client.address3,
                        city: this.client.city,
                        postcode: this.client.postcode,
                    }; 

                    BookingService.updateClient(this.accessToken, this.client.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.getClient();
                            alert("Client Updated")
                        }
                    ).catch((error) => { 
                        console.log(error)
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                        
                    });
            },
            async removeClient() {
                if(confirm('Are you sure you want to remove this client from the database ?'))
                {
                    this.working = true;
                
                    var formdata = { 
                        _method: 'patch',
                        active:0
                    }; 

                    BookingService.updateClient(this.accessToken, this.client.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.$router.push("/billing/client-groups/" + this.client.client_group_id);
                        }
                    ).catch((error) => { 
                        console.log(error)
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                        
                    });
                }
                else {
                    return false
                }
            },
            async hideClient() {
                if(confirm('Are you sure you want to hide this client in the database ? It will remain visible in the  admin areas but no bookings can be added for it.'))
                {
                    this.working = true;
                
                    var formdata = { 
                        _method: 'patch',
                        hide_client:1
                    }; 

                    BookingService.updateClient(this.accessToken, this.client.id, formdata)
                    .then(
                        () => {
                            this.working=false;
                            
                            this.$router.push("/billing/client-groups/" + this.client.client_group_id);
                        }
                    ).catch((error) => { 
                        console.log(error)
                        if (error.response.status == 409) {
                            alert(error.response.data.error)
                        } 
                        else{
                            alert(error.message)
                        } 
                        this.working=false;
                        
                    });
                }
                else {
                    return false
                }
            },
            async getClientGroupBillingProfiles() {
                this.client_group_billing_profiles = []

                UtilityService.getSimpleApiData(this.accessToken, "client_group_billing_profiles?client_group_id=" + this.client.client_group_id)
                .then(
                    (client_group_billing_profiles => {
                        this.$set(this, "client_group_billing_profiles", client_group_billing_profiles);
                    }).bind(this)
                );
            },
            async getClientGroups() {
                
                UtilityService.getSimpleApiData(this.accessToken, "client_groups")
                .then(
                    (client_groups => {
                        this.$set(this, "client_groups", client_groups);
                       
                    }).bind(this)
                );
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getClient();
                this.getClientGroups();
                this.getUserSelf()
                
            });
        
        },
        watch: {
            client_group_id: function() {
                this.getClientGroupBillingProfiles();
            },
           
        },
    }
</script>